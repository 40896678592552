<template>
  <div class="countdown">
    <slot name="time" :time="time" />
  </div>
</template>

<script>
import { ref, onUnmounted } from "vue";
export default {
  props: {
    timestamp: {
      type: Number,
      required: true,
    },
  },
  setup(props, { emit }) {
    const time = ref({
      d: "00",
      h: "00",
      m: "00",
      s: "00",
    });

    let timestamp = props.timestamp;
    let timer = null;

    const timeRender = () => {
      let nowTime = new Date();
      let endTime = new Date(timestamp * 1000);
      let t = endTime.getTime() - nowTime.getTime();
      if (t > 0) {
        let day = Math.floor(t / 86400000);
        let hour = Math.floor((t / 3600000) % 24);
        let min = Math.floor((t / 60000) % 60);
        let sec = Math.floor((t / 1000) % 60);
        day = day < 10 ? "0" + day : day;
        hour = hour < 10 ? "0" + hour : hour;
        min = min < 10 ? "0" + min : min;
        sec = sec < 10 ? "0" + sec : sec;
        time.value = {
          d: day,
          h: hour,
          m: min,
          s: sec,
        };
      } else {
        if (timer) clearInterval(timer);
        emit("timeOut");
      }
    };

    timeRender();
    timer = setInterval(function () {
      timeRender();
    }, 1000);

    onUnmounted(() => {
      if (timer) clearInterval(timer);
    });

    return {
      time,
    };
  },
};
</script>

<style lang="less" scoped>
.countdown {
  display: inline-block;
}
</style>