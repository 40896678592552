<template>
  <div class="card">
    <div class="cover">
      <img v-if="dataSource.coverImg" :src="dataSource.coverImg" alt="cover" />
      <img v-else :src="require(`@/assets/image/live-cover.png`)" alt="cover" />
      <div class="time" v-if="liveType == 1">
        <CountDown
          :timestamp="
            now < dataSource.startTime
              ? dataSource.startTime
              : dataSource.endTime
          "
        >
          <template #time="{ time }">
            <ClockCircleOutlined />
            <template v-if="now < dataSource.startTime">
              {{ $t("from_start") }}：</template
            >
            <!-- 距开始 -->
            <template v-else> {{ $t("from_end") }}：</template>
            <!-- 距结束 -->
            {{ time.d }} {{ $t("Pub_Day") }} {{ time.h }}:{{ time.m }}:{{
              time.s
            }}
          </template>
        </CountDown>
      </div>
      <div class="face-type" v-if="dataSource.trainType">
        {{ trainType[dataSource.trainType] }}
      </div>
    </div>
    <div class="content">
      <h3>{{ dataSource.taskName }}</h3>
      <p class="times">
        {{ $t("face.face_time") }}：{{ dateFormat(dataSource.startTime) }} ～
        {{ dateFormat(dataSource.endTime) }}
      </p>
      <div class="place">
        {{ $t("face.address") }}：{{ dataSource.address }}
      </div>
      <p class="bottom">
        <template v-if="timeType == 1">
          <span class="status" v-if="now < dataSource.startTime">
            {{ $t("CM_Status") }}：{{ $t("Pub_Tab_NotStart") }}
          </span>
          <!-- 状态：未开始 -->
          <span class="status" style="color: #266fff" v-else>
            {{ $t("CM_Status") }}：{{ $t("XB_UnderWay") }}
          </span>
          <!-- 状态：进行中 -->
        </template>
        <template v-else>
          <span
            class="status"
            style="color: #266fff"
            v-if="dataSource.complete == 1"
            >{{ $t("CM_Status") }}：{{ $t("CM_Completed") }}</span
          >
          <!-- 状态：已完成< -->
          <span
            class="status"
            style="color: #d78528"
            v-else-if="dataSource.complete == 2"
            >{{ $t("CM_Status") }}：{{ $t("CM_NotCompleted") }}</span
          >
          <!-- 状态：未完成 -->
          <span
            class="status"
            style="color: #c62222"
            v-else-if="dataSource.complete == 3"
            >{{ $t("CM_Status") }}：{{ $t("CM_NoTakePartIn") }}</span
          >
          <!-- 状态：未参与 -->
        </template>
        <span class="source" v-if="dataSource.taskType == 1"
          >{{ $t("LB_Doc_Source") }}：{{ dataSource.taskName }}</span
        >
        <!-- 来源： -->
      </p>
    </div>
    <div class="btns">
      <router-link
        class="link"
        :to="{
          path: '/train/detail',
          query: { id: dataSource.taskId, did: dataSource.detailId },
        }"
      >
        {{ $t("view_detail") }}
        <!-- 查看详情 -->
      </router-link>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { dateFormat } from "@/utils/tools";
import CountDown from "@/components/CountDown.vue";
import OpenData from "@/components/OpenData.vue";
export default {
  name: "LiveCard",
  components: {
    CountDown,
    OpenData,
  },
  props: {
    dataSource: {
      type: Object,
      default: {},
    },
    timeType: {
      type: Number,
      default: 1,
    },
  },
  setup() {
    const { t: $t } = useI18n();
    let now = Date.parse(new Date()) / 1000;
    const trainType = {
      1: $t("on_line"), // "线下",
      2: $t("offline"), // "线上",
      3: $t("LB_Blend"), // "混合",
    };

    return {
      dateFormat,
      now,
      trainType,
    };
  },
};
</script>

<style lang="less" scoped>
.card {
  height: 174px;
  background-color: #fff;
  transition: all 0.3s ease;
  .mixinFlex(space-between);
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  .cover {
    .mixinImgWrap(310px; 174px);
    position: relative;
    border-radius: 8px 0 0 8px;
    .time {
      background: rgba(21, 34, 27, 0.7);
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: 30px;
      font-size: 14px;
      color: #fff;
      line-height: 30px;
      padding: 0 10px;
    }
    .face-type {
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px 15px;
      background: rgba(171, 172, 172, 0.7);
      color: #fff;
      font-size: 14px;
    }
  }
  .content {
    width: calc(100% - 284px);
    padding: 0 14px;
    h3 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #333333;
      .mixinEllipsis(24px);
      margin-top: 24px;
    }
    .times {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #666666;
      margin-top: 12px;
    }
    .user {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #666666;
      margin-top: 19px;
      .mixinFlex();
      span {
        margin-right: 60px;
        img {
          vertical-align: text-bottom;
          width: 16px;
          height: 16px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .bottom {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #666666;
      margin-top: 23px;
      .mixinFlex();
      span {
        margin-right: 60px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 320px;
        img {
          vertical-align: text-bottom;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .btns {
    .mixinFlex(center; center);
    padding-right: 20px;
    .link {
      line-height: 36px;
      width: 90px;
      height: 36px;
      background: @color-theme;
      opacity: 0.7;
      border-radius: 33px;
      color: #fff;
      text-align: center;
    }
  }
  &:hover {
    transform: translateY(-4px);
  }
}
</style>
